<template>
    <div class="list">

        <div class="card p-1">
            <div class="card-header">
                <div class="container-fluid p-1">
                    <div class="row">
                        <div class="col-12">
                            姓名：<vxe-input v-model="tableData.name" type="text" placeholder="请输入名称"></vxe-input>
                            性别：<vxe-select v-model="tableData.sex" transfer>
                                <vxe-option v-for="item in sexList1" :key="item.value" :value="item.value"
                                    :label="item.label">
                                </vxe-option>
                            </vxe-select>
                            <vxe-button type="submit" status="primary" content="查询"></vxe-button>
                            <vxe-button type="reset" content="重置"></vxe-button>
                        </div>
                    </div>
                </div>
                <div class="container-fluid p-1">
                    <div class="row">
                        <div class="col-12">
                            <vxe-button status="primary" icon="vxe-icon-search">图标按钮</vxe-button>
                            <vxe-button status="primary" icon="vxe-icon-search">图标按钮</vxe-button>
                            <vxe-button status="primary" icon="vxe-icon-plus">图标按钮</vxe-button>
                        </div>
                    </div>
                </div>





            </div>
            <div class="card-body table-responsive p-0 container-fluid">
                <vxe-table border :data="tableData">
                    <vxe-column type="checkbox" width="60"></vxe-column>
                    <vxe-column type="seq" title="Seq" width="60"></vxe-column>
                    <vxe-column field="name" show-overflow="ellipsis" title="店名"></vxe-column>
                    <vxe-column field="addr" title="地址"></vxe-column>
                    <vxe-column field="lot" title="经度"></vxe-column>
                    <vxe-column field="lat" title="纬度"></vxe-column>
                    <vxe-column field="create_time" title="创建时间"></vxe-column>
                    <template #empty>
                        <span style="color: red;">
                            <img src="https://n.sinaimg.cn/sinacn17/w120h120/20180314/89fc-fyscsmv5911424.gif">
                            <p>没有更多数据了！</p>
                        </span>
                    </template>
                </vxe-table>
            </div>
            <div class="card-footer clearfix">
                <vxe-pager background v-model:current-page="page.page" v-model:page-size="page.size" :total="page.total"
                    @page-change="handlePageChange" :layouts="['JumpNumber', 'Sizes', 'Total']">
                </vxe-pager>
            </div>
        </div>
    </div>

</template>

<script>
    import {
        getBusiness
    } from '@/requests/api'
    export default {
        name: 'List',
        component: {

        },
        created() {
            getBusiness({
                page: this.page.page,
                size: this.page.size
            }).then(res => {
                this.tableData = res.data
                this.page.total = res.total
            })
        },
        methods: {
            handlePageChange(e) {
                console.log(e)
                getBusiness({
                    page: e.currentPage,
                    size: e.pageSize
                }).then(res => {
                    this.tableData = res.data
                    this.page.total = res.total
                })
            }
        },
        data() {
            return {
                page: {
                    page: 1,
                    size: 10,
                    total: 300
                },
                sexList1: [{
                        value: '1',
                        label: '男'
                    },
                    {
                        value: '0',
                        label: '女'
                    }
                ],
                tableData: []
            }
        }
    }
</script>

<style>
</style>
